<template>
  <article v-if="mNote" class="media box has-margin-bottom">
    <div class="media-content">
      <div class="content">
        <div v-if="showItemName && mNote.ItemName">[{{ mNote.ItemName }}]</div>

        <div>
          <strong>
            <span>{{ mNote.Subject }}</span>
          </strong>
        </div>

        <div v-if="mNote.Body.length > 0">{{ mNote.Body }}</div>

        <div class="is-size-7 has-text-grey has-text-weight-light">
          <span class="has-margin-right"
            >Created by {{ mNote.CreatedByName }} -
            {{ mNote.CreatedOn | convertTimeStampToIsoDateTimeString }}</span
          >
          <span>
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'user']" />
            </span>
            <span>{{ nrOfNotifications }}</span>
          </span>
        </div>
      </div>
      <nav class="level is-mobile">
        <div class="level-left is-size-7">
          <a @click="shareNote" class="level-item"
            ><span class="icon">
              <font-awesome-icon :icon="['fas', 'share']" />
            </span>
            <span>{{
              $t('Components.Notes.NoteItem.Card_Footer_Share')
            }}</span></a
          >
          <a @click="editNote" class="level-item">
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'edit']" />
            </span>
            <span>{{
              $t('Components.Notes.NoteItem.Card_Footer_Edit')
            }}</span></a
          >
          <a @click="deleteNote" class="level-item"
            ><span class="icon">
              <font-awesome-icon :icon="['fas', 'trash-alt']" />
            </span>
            <span>{{
              $t('Components.Notes.NoteItem.Card_Footer_Delete')
            }}</span></a
          >
        </div>
      </nav>
    </div>
    <div v-if="showLink && note.LinkTypeId !== 'None'" class="media-right">
      <a @click="goToItem()" class="is-small">
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'share']" />
        </span>
      </a>
    </div>
  </article>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: {
    note: {
      default: null,
      type: Object,
    },

    viewType: {
      default: 'normal',
      type: String,
    },

    showItemName: {
      type: Boolean,
      default: false,
    },

    showLink: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      currentDate: new Date().getTime(),
      mNote: this.note,
    }
  },

  computed: {
    nrOfNotifications() {
      let total = 0

      if (this.mNote) {
        total = this.mNote.Notifications.length
      }

      return total
    },
  },

  methods: {
    ...mapMutations('locationStore', ['setLocation']),

    editNote() {
      this.$emit('editNote', this.note)
    },

    shareNote() {
      this.$emit('shareNote', this.note)
    },

    deleteNote() {
      this.$emit('deleteNote', this.note)
    },

    goToItem() {
      switch (this.note.LinkTypeId) {
        case 'Location':
          this.goToLocation(this.note.LinkItemId)
          break
        case 'Reservation':
          this.goToReservation(this.note.LinkItemId)
          break
        case 'Option':
          this.gotToOption(this.note.LinkItemId)
          break
        case 'Space':
          this.goToSpace(this.note.LinkItemId)
          break
        case 'Invoice':
          this.goToInvoice(this.note.LinkItemId)
          break
        case 'Company':
          this.goToCompany(this.note.LinkItemId)
          break
        case 'Customer':
          this.goToCustomer(this.note.LinkItemId)
          break
        case 'Voucher':
          this.goToVoucher(this.note.LinkItemId)
          break
        case 'Event':
          this.goToEvent(this.note.LinkItemId)
          break
        case 'ProposalRequest':
          this.goToProposalRequest(this.note.LinkItemId)
          break
      }
    },

    goToCompany(companyId) {
      this.$router.push({
        name: `crm-companies-detail`,
        params: { companyId: companyId },
      })
    },

    goToCustomer(customerId) {},

    goToEvent(eventId) {
      this.$router.push({
        name: `events-list-detail`,
        params: { eventId: eventId },
      })
    },

    goToInvoice(invoiceId) {
      this.$router.push({
        name: `invoices-list-detail`,
        params: { invoiceId: invoiceId },
      })
    },

    goToLocation(locationId) {
      this.setLocation(null)

      this.$router.push({
        name: `locations-detail`,
        params: { channelId: this.channelId, locationId: locationId },
      })
    },

    gotToOption(optionId) {
      this.$router.push({
        name: `options-detail`,
        params: { optionId: optionId },
      })
    },

    goToProposalRequest(requestId) {
      this.$router.push({
        name: `proposals-requests-detail`,
        params: { requestId: requestId },
      })
    },

    goToReservation(reservationId) {
      this.$router.push({
        name: `reservations-list-detail`,
        params: { reservationId: reservationId },
      })
    },

    goToSpace(spaceId) {
      this.$router.push({
        name: `spaces-detail`,
        params: { spaceId: spaceId },
      })
    },

    goToVoucher(voucherId) {
      this.$router.push({
        name: `vouchers-detail`,
        params: { voucherId: voucherId },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.is-note {
  background: #ffc;
}
</style>
